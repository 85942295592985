import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider!=!/opt/build/repo/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/antd/lib/theme/index.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"src/app/(default)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Inter_18pt-Thin.ttf\",\"weight\":\"100\"},{\"path\":\"../../public/fonts/Inter_18pt-ExtraLight.ttf\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/Inter_18pt-Light.ttf\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/Inter_18pt-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Inter_18pt-Medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/Inter_18pt-SemiBold.ttf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/Inter_18pt-Bold.ttf\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/Inter_18pt-ExtraBold.ttf\",\"weight\":\"800\"}],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/slick-carousel/slick/slick-theme.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/ContentWrapper.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/styles/blog_content.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/AuthContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/ReportIssueContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/ShareContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/TimezoneContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/react-query-provider.tsx")