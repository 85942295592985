"use client";

import classNames from "classnames";
import AdUnit from "./AdUnit";

export default function AdsBanner({ isSmallSize = false }: { isSmallSize?: boolean }) {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 lg:grid-cols-12 max-w-[1312px] mx-auto p-4 lg:p-0 mt-3",
        isSmallSize ? "gap-2" : "gap-6",
      )}
    >
      <div className="col-span-1 lg:col-span-8">
      <AdUnit slot="9249827769" />
      </div>

      <div className="col-span-1 lg:col-span-4">
      <AdUnit slot="7421740772" />
      </div>
    </div>
  );
}
